<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-breadcrumbs
        class="pl-0 primary--text"
        :items="navigator"
        divider="/"
      ></v-breadcrumbs>
    </v-col>
    <v-col class="pb-1 pt-1" cols="12">
      <strong class="primary--text devTitleType">Resume Builder</strong>
    </v-col>

    <v-col cols="12" lg="7" class="px-0">
      <v-stepper v-model="e6" vertical flat>
        <v-stepper-step :complete="e6 > 1" step="1">
          Fresher or Experienced ?
          <small class="mt-1"
            >Please select as per your experience to proceed</small
          >
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card
            class="mb-5 pa-5 d-flex justify-space-around"
            height="max-content"
            width="100%"
          >
            <v-badge
              color="green"
              :value="user.value"
              icon="mdi-check"
              overlap
              bordered
              v-for="(user, index) in userType"
              :key="index"
            >
              <v-card
                class="rscBox pa-2"
                elevation="8"
                outlined
                @click="selectUserProfession(user, index)"
                :class="user.value == 1 ? 'rscBox-active' : ''"
              >
                <v-img :src="user.img" contain class="ma-3 mb-0 rscCardImg">
                </v-img>
                <v-card-subtitle
                  class="justify-center prof-title text-center pt-0"
                  >{{ user.type }}</v-card-subtitle
                >
              </v-card>
            </v-badge>
          </v-card>
          <v-btn color="primary" @click="e6 = 2" :disabled="!selectedUserType">
            Continue
          </v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="e6 > 2" step="2">
          Select a Template
          <small class="mt-1">Please select a template to proceed</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card class="mb-5" height="max-content" width="100%">
            <v-card-text class="d-flex px-0">
              <v-slide-group
                v-model="selectModel"
                active-class="success"
                show-arrows
              >
                <v-slide-item
                  v-for="(template, index) in templates"
                  :key="index"
                >
                  <div
                    style="width: max-content"
                    v-if="template.type == selectedUserType"
                    class="mr-8 mt-3"
                  >
                    <v-badge
                      color="green"
                      :value="template.value"
                      icon="mdi-check"
                      overlap
                      bordered
                    >
                      <v-img
                        :src="require('@/assets/' + template.path)"
                        contain
                        width="140px"
                        @click="selectTemplate(template, index)"
                      />
                    </v-badge>
                  </div>
                </v-slide-item>
              </v-slide-group>
            </v-card-text>
          </v-card>
          <v-btn color="primary" @click="e6 = 3" :disabled="!SelectedTemplate">
            Continue
          </v-btn>
          <v-btn text @click="e6 = 1" class="ml-2"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">
          Personal Information
          <small class="mt-1">All fields are mandatory</small>
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card class="mb-5" min-height="200px">
            <v-card-text>
              <div class="d-flex">
                <v-avatar color="#111111" size="110">
                  <template v-if="profData.photoURL">
                    <v-hover v-slot="{ hover }">
                      <v-img
                        class="imageBox"
                        :src="profData.photoURL"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-btn
                          class="changeImg"
                          icon
                          text
                          @click="$refs.file.click()"
                          :class="{ 'show-btns': hover }"
                          :color="transparentColor"
                        >
                          <v-icon
                            dark
                            :class="{ 'show-btns': hover }"
                            :color="transparentColor"
                            >mdi-camera</v-icon
                          >
                        </v-btn>
                      </v-img>
                    </v-hover>
                  </template>
                  <template v-else>
                    <v-btn icon text @click="$refs.file.click()">
                      <v-icon dark>mdi-camera</v-icon>
                    </v-btn>
                  </template>
                </v-avatar>

                <input
                  type="file"
                  ref="file"
                  v-show="false"
                  @change="onFileChange"
                  accept="image/*"
                />
                <div class="ml-5" style="width: 100%">
                  <div
                    :class="
                      this.$vuetify.breakpoint.name != 'xs' &&
                      selectedUserType == 'Experienced'
                        ? 'd-flex'
                        : ''
                    "
                  >
                    <v-text-field
                      dense
                      outlined
                      label="First Name"
                      class="mr-2"
                      v-model="profData.firstname"
                    ></v-text-field>
                    <v-text-field
                      dense
                      outlined
                      label="Last Name"
                      v-model="profData.lastname"
                    ></v-text-field>
                  </div>
                  <v-text-field
                    dense
                    outlined
                    v-if="selectedUserType == 'Experienced'"
                    label="Designation"
                    v-model="profData.designation"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  outlined
                  type="telephone"
                  label="Contact"
                  class="mr-2"
                  v-model="profData.tel"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  type="email"
                  label="Email"
                  class="mr-2"
                  v-model="profData.email"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  type="url"
                  label="website"
                  v-model="profData.website"
                ></v-text-field>
              </div>
              <v-textarea
                label="Overview"
                v-model="profData.Overview"
                outlined
                dense
              ></v-textarea>
            </v-card-text>
          </v-card>
          <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
          <v-btn text @click="e6 = 2" class="ml-2"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 4" step="4">
          Skills & Hobbies
        </v-stepper-step>

        <v-stepper-content step="4">
          <v-card class="mb-5" min-height="200px">
            <v-card-text>
              <v-combobox
                v-model="profData.skills"
                label="Skills"
                class="mb-3"
                multiple
                chips
                outlined
                deletable-chips
                hint="Press ENTER or TAB to add skill and BACKSPACE to remove"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-avatar
                      class="primary white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
              <v-combobox
                v-model="profData.hobbies"
                label="Hobbies"
                multiple
                class="mb-3"
                chips
                outlined
                deletable-chips
                hint="Press ENTER or TAB to add hobbies and BACKSPACE to remove"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-avatar
                      class="primary white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
              <v-combobox
                v-model="profData.langs"
                label="Languages"
                multiple
                chips
                outlined
                deletable-chips
                hint="Press ENTER or TAB to add language and BACKSPACE to remove"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-avatar
                      class="primary white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
          <v-btn color="primary" @click="e6 = 5"> Continue </v-btn>
          <v-btn text @click="e6 = 3" class="ml-2"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 5" step="5">
          {{ selectedUserType == "Experienced" ? "Work" : "Project" }}
          Experience
          <small class="mt-1">Enter all data in chronical order</small>
        </v-stepper-step>
        <v-stepper-content step="5">
          <v-card class="mb-5" min-height="200px">
            <v-card-text>
              <v-expansion-panels v-model="expandedProject">
                <v-expansion-panel
                  v-for="(item, i) in totalProject"
                  :key="i"
                  style="background: #111111"
                >
                  <v-expansion-panel-header class="primary--text">
                    {{ "Project " + (i + 1) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <projectForm
                      :data="profData.workExp[i]"
                      :userType="selectedUserType"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
          <v-card
            flat
            height="max-content"
            class="d-flex justify-center align-center pa-3 mb-4"
          >
            <v-btn color="primary" @click="addProject()" small
              >Add New Project</v-btn
            >
            <v-btn color="primary" @click="removeProject()" class="ml-4" small
              >Remove Project</v-btn
            >
          </v-card>
          <v-btn color="primary" @click="e6 = 6"> Continue </v-btn>
          <v-btn text @click="e6 = 4" class="ml-2"> Back </v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="e6 > 6" step="6">
          Education & Achievements
          <small class="mt-1">Enter all data in chronical order</small>
        </v-stepper-step>

        <v-stepper-content step="6">
          <v-card class="mb-5" min-height="200px">
            <v-combobox
              v-model="qualificationSelected"
              :items="qualificationList"
              label="Qualifications"
              outlined
              class="pa-3 pb-0"
              multiple
              chips
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar
                    class="primary white--text"
                    left
                    v-text="data.item.slice(0, 1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
            <v-card-text class="pt-0">
              <v-expansion-panels v-model="expandedEdq">
                <v-expansion-panel
                  v-for="(item, i) in qualificationSelected"
                  :key="i"
                  style="background: #111111"
                >
                  <v-expansion-panel-header class="primary--text">
                    {{ item }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <qualificationForm :data="profData.education[i]" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
          <v-btn color="primary" @click="e6 = 7"> Continue </v-btn>
          <v-btn text @click="e6 = 5" class="ml-2"> Back </v-btn>
        </v-stepper-content>
        <v-stepper-step step="7"> Preview & Print </v-stepper-step>

        <v-stepper-content step="7">
          <v-card
            class="mb-5 d-flex justify-center align-center"
            height="200px"
          >
            <v-dialog v-model="dialog" width="max-content">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-eye</v-icon>
                  Preview
                </v-btn>
              </template>

              <v-card>
                <v-card-title
                  >Resume Final Look <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialog = false"
                    class="ma-2"
                  >
                    Exit
                  </v-btn></v-card-title
                >
                <v-card-text class="pt-3">
                  <component
                    :is="SelectedTemplate"
                    :data="profData"
                    class="ma-3"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
          <v-btn color="primary" dark @click="printPDF"> Download PDF </v-btn>
          <v-btn text @click="e6 = 6" class="ml-2"> Back </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-col>
    <v-col cols="12" lg="5" class="pr-0">
      <v-card flat height="max-content" width="100vh" style="overflow: auto">
        <template v-if="!SelectedTemplate">
          <v-card-text
            class="d-flex justify-center align-center flex-wrap"
            style="height: 100%; min-height: 70vh"
          >
            <h2>Select Template to Preview</h2>
          </v-card-text>
        </template>
        <template v-else>
          <component
            v-if="e6 !== 1"
            :is="SelectedTemplate"
            :data="profData"
            :key="reloadTemplate"
          />
          <v-card
            v-if="e6 == 1"
            class="d-flex justify-center align-center pa-2"
            style="height: 100%; min-height: 70vh"
          >
            <v-img :src="require('@/assets/' + templatePath)" contain />
          </v-card>
        </template>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card tile>
        <v-card-title class="primary--text devTitleType"
          >What is Resume Builder ?</v-card-title
        >
        <v-card-text class="devTextType"
          ><p>
            In simple terms, a resume builder is a handy tool that helps you
            create a good-looking resume without having to worry too much about
            the design. It guides you through the process and makes it easy to
            customize your resume to fit your needs.
          </p>
          <p>
            A resume builder is like a special tool or computer program that
            helps you create a professional-looking resume. It's like a
            step-by-step guide that gives you templates to choose from and asks
            you to fill in your personal information, like your contact details,
            education, work experience, and skills.
          </p>
          <p>
            The resume builder takes care of making your resume look good by
            handling things like the font, layout, and spacing. It divides your
            resume into different sections, like work experience and education,
            so it's organized and easy to read.
          </p>
          <p>
            You can customize your resume by moving sections around, adding or
            removing information, and making it look just the way you want. The
            resume builder also lets you see a preview of how your resume will
            look while you're working on it.
          </p>
          <p>
            Once you're done, the resume builder lets you download your resume
            in different formats, like PDF or Word documents, so you can send it
            to employers or print it out. Some resume builders even let you
            share your resume directly through email or social media.
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import profTemplate1 from "@/components/BuilderTool/ResumeTemplates/ProfTemplate1.vue";
import profTemplate2 from "@/components/BuilderTool/ResumeTemplates/ProfTemplate2.vue";
import profTemplate3 from "@/components/BuilderTool/ResumeTemplates/ProfTemplate3.vue";
import profTemplate4 from "@/components/BuilderTool/ResumeTemplates/ProfTemplate4.vue";
import profTemplate5 from "@/components/BuilderTool/ResumeTemplates/ProfTemplate5.vue";
import profTemplate6 from "@/components/BuilderTool/ResumeTemplates/ProfTemplate6.vue";
import clgTemplate1 from "@/components/BuilderTool/ResumeTemplates/ClgTemplate1.vue";
import clgTemplate2 from "@/components/BuilderTool/ResumeTemplates/ClgTemplate2.vue";
import clgTemplate3 from "@/components/BuilderTool/ResumeTemplates/ClgTemplate3.vue";
import clgTemplate4 from "@/components/BuilderTool/ResumeTemplates/ClgTemplate4.vue";
import clgTemplate5 from "@/components/BuilderTool/ResumeTemplates/ClgTemplate5.vue";
import clgTemplate6 from "@/components/BuilderTool/ResumeTemplates/ClgTemplate6.vue";
import experiencedSVG from "@/assets/experienced.svg";
import fresherSVG from "@/assets/fresher.svg";
import projectForm from "@/components/BuilderTool/ResumeTemplates/ProjectForm.vue";
import qualificationForm from "@/components/BuilderTool/ResumeTemplates/QualificationForm.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { scrollToTop } from "@/helpers/helperFunctions.js";

export default {
  components: {
    profTemplate1,
    profTemplate2,
    profTemplate3,
    profTemplate4,
    profTemplate5,
    profTemplate6,
    clgTemplate1,
    clgTemplate2,
    clgTemplate3,
    clgTemplate4,
    clgTemplate5,
    clgTemplate6,
    projectForm,
    qualificationForm,
  },
  data() {
    return {
      e6: 1,
      dialog: false,
      templatePath: null,
      SelectedTemplate: null,
      transparentColor: "rgba(255, 255, 255, 0)",
      expandedEdq: 0,
      selectModel: null,
      qualificationList: [
        "High School (10th)",
        "Intermediate (12th)",
        "Diploma",
        "Graduation",
        "Post Graduation",
      ],
      userType: [
        {
          type: "Fresher",
          value: 0,
          img: fresherSVG,
        },
        {
          type: "Experienced",
          value: 0,
          img: experiencedSVG,
        },
      ],
      selectedUserType: null,
      experiencedSVG,
      fresherSVG,
      qualificationSelected: ["High School (10th)"],
      totalEdq: 0,
      reloadTemplate: 1,
      totalProject: 2,
      expandedProject: 0,
      profData: {
        firstname: "Name",
        lastname: "Surname",
        designation: "XYZ Engineer",
        tel: "+ 91 9853835321",
        email: "xyz.example.com",
        website: "xyz.domain.com",
        photoURL: "",
        Overview:
          "Versatile, motivated and passionate web developer of new technologies. Having gained experience with multiple technologies and completing several development projects, whether in business or freelance. Are actively seeking a Web Developer position within a dynamic team and with technical and professional development opportunities.",
        skills: ["HTML5", "CSS3", "JavaScript", "Node JS"],
        hobbies: ["Football", "Cricket", "Photography"],
        langs: ["English", "Hindi", "Bhojpuri"],
        workExp: [
          {
            projectID: 0,
            projectName: "Project 1",
            fromDate: "2018-05",
            toDate: "2019-08",
            designation: "Software Engineer",
            detail:
              "Versatile, motivated and passionate web developer of new technologies. Having gained experience with multiple technologies and completing several development projects, whether in business or freelance. Are actively seeking a Web Developer position within a dynamic team and with technical and professional development opportunities.",
            company: "XYZ Company",
            tags: [
              "HTML5",
              "CSS3",
              "JavaScript",
              "VueJS",
              "BootStrap",
              "NodeJS",
              "ReactJS",
            ],
          },
          {
            projectID: 1,
            projectName: "Project 2",
            fromDate: "2018-05",
            toDate: "2019-08",
            designation: "Software Engineer",
            detail:
              "Versatile, motivated and passionate web developer of new technologies. Having gained experience with multiple technologies and completing several development projects, whether in business or freelance. Are actively seeking a Web Developer position within a dynamic team and with technical and professional development opportunities.",
            company: "XYZ Company",
            tags: [
              "HTML5",
              "CSS3",
              "JavaScript",
              "VueJS",
              "BootStrap",
              "NodeJS",
              "ReactJS",
            ],
          },
        ],
        education: [
          {
            school: "XYZ School",
            course: "XYZ Course",
            passout: "2018",
            percent: "95 %",
            degree: "High School (10th)",
          },
        ],
      },
      navigator: [
        {
          text: "Builder Tools",
          disabled: false,
          href: "",
        },
        {
          text: "Resume Builder",
          disabled: true,
          href: "",
        },
      ],
      templates: [
        {
          path: "Template1.png",
          value: 0,
          component: profTemplate1,
          type: "Experienced",
        },
        {
          path: "Template1.png",
          value: 0,
          component: clgTemplate1,
          type: "Fresher",
        },
        {
          path: "Template2.png",
          value: 0,
          component: profTemplate2,
          type: "Experienced",
        },
        {
          path: "Template2.png",
          value: 0,
          component: clgTemplate2,
          type: "Fresher",
        },
        {
          path: "Template3.png",
          value: 0,
          component: profTemplate3,
          type: "Experienced",
        },
        {
          path: "Template3.png",
          value: 0,
          component: clgTemplate3,
          type: "Fresher",
        },
        {
          path: "Template4.png",
          value: 0,
          component: profTemplate4,
          type: "Experienced",
        },
        {
          path: "Template4.png",
          value: 0,
          component: clgTemplate4,
          type: "Fresher",
        },
        {
          path: "Template5.png",
          value: 0,
          component: profTemplate5,
          type: "Experienced",
        },
        {
          path: "Template5.png",
          value: 0,
          component: clgTemplate5,
          type: "Fresher",
        },
        {
          path: "Template6.png",
          value: 0,
          component: profTemplate6,
          type: "Experienced",
        },
        {
          path: "Template6.png",
          value: 0,
          component: clgTemplate6,
          type: "Fresher",
        },
      ],
    };
  },
  watch: {
    qualificationSelected() {
      let tempData = this.profData.education.map((ed) => {
        return ed.degree;
      });

      this.qualificationSelected.map((item) => {
        if (!tempData.includes(item)) {
          this.profData.education.push({
            school: "XYZ School",
            course: "XYZ Course",
            passout: "2018",
            percent: "95 %",
            degree: item,
          });
        }
      });

      this.profData.education.map((temp, i) => {
        if (!this.qualificationSelected.includes(temp.degree)) {
          this.profData.education.splice(i, 1);
        }
      });
    },
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) this.profData.photoURL = "";
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => (this.profData.photoURL = e.target.result);
      reader.readAsDataURL(file);
    },
    printPDF() {
      const templateData = document.getElementById("resumeTemplate");
      const divWidth = document.getElementById("resumeTemplate").offsetWidth;
      const divHeight = document.getElementById("resumeTemplate").offsetHeight;
      const filename =
        this.profData.firstname + " " + this.profData.lastname + "_Resume.pdf";

      var top_left_margin = 15;
      var PDF_Width = divWidth + top_left_margin * 2;
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      var canvas_image_width = divWidth;
      var canvas_image_height = divHeight;

      var totalPDFPages = Math.ceil(divHeight / PDF_Height) - 1;

      html2canvas(templateData, { allowTaint: true }).then(function (canvas) {
        canvas.getContext("2d");

        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPEG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );

        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(
            imgData,
            "JPEG",
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }

        pdf.save(filename);

        this.reloadTemplate++;
      });
    },
    selectTemplate(template, i) {
      this.SelectedTemplate = null;
      this.templates.map((item) => {
        item.value = 0;
      });
      this.templates[i].value = 1;
      this.templatePath = template.path;
      this.SelectedTemplate = template.component;
    },
    selectUserProfession(user, i) {
      this.selectedUserType = null;
      this.userType.map((item) => {
        item.value = 0;
      });
      this.userType[i].value = 1;
      this.selectedUserType = user.type;
    },
    addProject() {
      this.profData.workExp.push({
        projectID: this.totalProject,
        projectName: "Project Name",
        fromDate: new Date().toISOString().substr(0, 7),
        toDate: new Date().toISOString().substr(0, 7),
        designation: "XYZ Engineer",
        detail:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        company: "XYZ Company",
        tags: ["tech 1", "tech 2"],
      });
      this.totalProject++;
      setTimeout(() => {
        this.expandedProject = this.totalProject - 1;
      }, 500);
    },
    removeProject() {
      this.profData.workExp.splice(-1);
      this.totalProject--;
      setTimeout(() => {
        this.expandedProject = this.totalProject - 1;
      }, 500);
    },
  },
  mounted() {
    scrollToTop();
  },
};
</script>
<style scoped>
.theme--dark.v-stepper {
  background: inherit !important;
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.imageBox {
  position: relative;
}
.imageBox:hover {
  background: black;
  opacity: 0.5;
}

.changeImg {
  position: absolute;
  left: 35%;
  top: 35%;
}
.rscBox {
  background: #1e1e1e !important;
  /* border: 1px solid #ff6700 !important; */
  color: #ff6700 !important;
  width: 180px;
  height: 180px;
}
.rscCardImg {
  height: 80%;
  width: 80%;
}
.rscBox:hover {
  box-shadow: 0px 0px 11px #ff6700 !important;
}

.prof-title {
  font-size: 20px;
}

.rscBox-active {
  box-shadow: 0px 0px 11px #ff6700 !important;
}

@media screen and (max-width: 450px) {
  .rscBox {
    width: 100px;
    height: 100px;
  }

  .prof-title {
    font-size: 9px;
  }
  .rscCardImg {
    height: 70%;
    width: 70%;
  }
}

@media screen and (min-width: 1260px) and (max-width: 1400px) {
  .rscBox {
    width: 160px;
    height: 160px;
    margin-left: 0px;
  }
  .prof-title {
    font-size: 18px;
  }
}
</style>
