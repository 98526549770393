import { render, staticRenderFns } from "./ClgTemplate4.vue?vue&type=template&id=a123c1d0&scoped=true&"
import script from "./ClgTemplate4.vue?vue&type=script&lang=js&"
export * from "./ClgTemplate4.vue?vue&type=script&lang=js&"
import style0 from "./ClgTemplate4.vue?vue&type=style&index=0&id=a123c1d0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a123c1d0",
  null
  
)

export default component.exports