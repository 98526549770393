<template>
  <v-container class="px-0">
    <v-text-field
      dense
      outlined
      type="text"
      label="Project"
      v-model="data.projectName"
      class="mr-2"
    ></v-text-field>
    <div v-if="userType == 'Experienced'" class="d-flex">
      <v-menu
        ref="fromMenu"
        v-model="fromMenu"
        :close-on-content-click="false"
        :return-value.sync="fromDate"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromDate"
            label="From"
            append-icon="mdi-calendar"
            class="mr-2"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="fromDate"
          type="month"
          color="primary"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="fromMenu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.fromMenu.save(fromDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
        ref="toMenu"
        v-model="toMenu"
        :close-on-content-click="false"
        :return-value.sync="toDate"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toDate"
            label="To"
            append-icon="mdi-calendar"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="toDate"
          type="month"
          color="primary"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="toMenu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.toMenu.save(toDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <div v-if="userType == 'Experienced'" class="d-flex">
      <v-text-field
        dense
        v-model="data.designation"
        outlined
        type="text"
        label="Designation"
        class="mr-2"
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="data.company"
        type="text"
        label="Company Name"
      ></v-text-field>
    </div>

    <v-textarea
      label="Details"
      v-model="data.detail"
      outlined
      dense
    ></v-textarea>
    <v-combobox
      v-model="data.tags"
      label="Tech Stack"
      multiple
      chips
      outlined
      deletable-chips
      hint="Press ENTER or TAB to add teck stack and BACKSPACE to remove"
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            class="primary white--text"
            left
            v-text="data.item.slice(0, 1).toUpperCase()"
          ></v-avatar>
          {{ data.item }}
        </v-chip>
      </template>
    </v-combobox>
  </v-container>
</template>
<script>
export default {
  props: ["data", "userType"],
  data() {
    return {
      fromDate: null,
      fromMenu: false,
      toDate: null,
      toMenu: false,
    };
  },
  created() {
    this.fromDate = this.data.fromDate
      ? new Date(this.data.fromDate).toISOString().substr(0, 7)
      : new Date().toISOString().substr(0, 7);
    this.toDate = this.data.toDate
      ? new Date(this.data.toDate).toISOString().substr(0, 7)
      : new Date().toISOString().substr(0, 7);
  },
};
</script>
