<template>
  <v-container
    id="resumeTemplate"
    style="height: max-content; background: white; width: max-content"
    class="pa-0"
  >
    <v-row>
      <v-col cols="3" class="pa-0 pl-3">
        <v-sheet color="bgColor lighten-1" height="100%">
          <v-card
            flat
            height="240px"
            color="transparent"
            class="d-flex flex-column align-center"
          >
            <v-avatar color="grey" size="148" class="mt-4">
              <v-img :src="data.photoURL"></v-img>
            </v-avatar>
            <h2 class="black--text text--h1">
              {{ data.firstname }} {{ data.lastname }}
            </h2>
            <!-- <h4 class="black--text" style="font-weight: normal">
              {{ data.designation }}
            </h4> -->
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat height="120px" color="transparent">
            <v-card-text class="px-2">
              <h4 class="pl-5 mb-1">
                <v-icon class="mr-2">mdi-phone-in-talk</v-icon> {{ data.tel }}
              </h4>
              <h4 class="pl-5 mb-1">
                <v-icon class="mr-2">mdi-email</v-icon> {{ data.email }}
              </h4>
              <h4 class="pl-5 mb-1">
                <v-icon class="mr-2">mdi-earth</v-icon> {{ data.website }}
              </h4>
            </v-card-text>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat height="max-content" class="pl-3" color="transparent">
            <v-card-title
              ><v-icon class="mr-2">mdi-wrench</v-icon> Skills</v-card-title
            >
            <v-card-text>
              <h4 class="pl-2" v-for="(item, i) in data.skills" :key="i">
                {{ item }} <span class="caption">( Intermediate)</span>
              </h4>
            </v-card-text>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat height="max-content" class="pl-3" color="transparent">
            <v-card-title
              ><v-icon class="mr-2">mdi-football</v-icon>Hobbies</v-card-title
            >
            <v-card-text>
              <h4 class="pl-2" v-for="(item, i) in data.hobbies" :key="i">
                {{ item }}
              </h4>
            </v-card-text>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat height="max-content" class="pl-3" color="transparent">
            <v-card-title
              ><v-icon class="mr-2">mdi-google-translate</v-icon
              >Languages</v-card-title
            >
            <v-card-text>
              <h4 class="pl-2" v-for="(item, i) in data.langs" :key="i">
                {{ item }} <span class="caption">(Proficient)</span>
              </h4>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col cols="9" class="pa-0">
        <v-sheet height="100%" color="white">
          <v-card
            flat
            height="max-content"
            min-height="100px"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-account-edit</v-icon
              >Overview</v-card-title
            >
            <v-card-text class="black--text">
              {{ data.Overview }}
            </v-card-text>
          </v-card>
          <v-divider class="bgColor"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            color="transparent"
          >
            <v-card-title class="textColor pb-0"
              ><v-icon class="mr-2 textColor" large>mdi-briefcase</v-icon
              >Project Experience</v-card-title
            >
            <v-card-text class="black--text">
              <v-card
                v-for="item in data.workExp"
                :key="item.projectID"
                flat
                color="transparent"
                class="pt-0"
              >
                <v-card-title class="textColor"
                  >{{ item.projectName }}
                  <!-- <v-spacer></v-spacer>
                  <span class="caption" style="font-weight: bold">{{
                    `From: ${item.fromDate} - To: ${item.toDate}`
                  }}</span> -->
                </v-card-title>
                <!-- <v-card-subtitle class="textColor lighten-1"
                  >Company- {{ item.company }}
                  <br />
                  Designation - <em>{{ item.designation }}</em></v-card-subtitle
                > -->
                <v-card-text class="black--text">
                  {{ item.detail }}
                  <br />
                  <v-chip
                    class="bgColor mr-2 mt-2"
                    label
                    x-small
                    dark
                    v-for="(tag, i) in item.tags"
                    :key="i"
                    >{{ tag }}</v-chip
                  >
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <v-divider class="bgColor"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-school</v-icon>Education
              & Qualification</v-card-title
            >
            <v-card-text class="black--text">
              <v-list two-line color="white">
                <v-list-item-group multiple>
                  <v-list-item v-for="(item, i) in data.education" :key="i">
                    <v-list-item-content>
                      <v-list-item-title class="textColor">{{
                        item.school
                      }}</v-list-item-title>

                      <v-list-item-subtitle class="black--text"
                        ><strong>School -</strong>
                        {{ item.school }}</v-list-item-subtitle
                      >

                      <v-list-item-subtitle class="black--text"
                        ><strong>Course -</strong>
                        {{ item.course }}</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action class="pt-3">
                      <v-list-item-action-text class="black--text"
                        ><strong>Passing Year -</strong>
                        {{ item.passout }}</v-list-item-action-text
                      >
                      <v-list-item-action-text class="black--text"
                        ><strong>Percent/Grades -</strong>
                        {{ item.percent }}</v-list-item-action-text
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["data"],
  created() {
    console.log(this.data);
  },
};
</script>
<style scoped>
.bgColor {
  background-color: #1867c0 !important;
}

.textColor {
  color: #1867c0 !important;
}
</style>
