<template>
  <v-container
    id="resumeTemplate"
    style="height: max-content; background: white; width: max-content"
    class="pa-0"
  >
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-sheet
          color="bgColor lighten-1"
          height="180px"
          width="100%"
          class="pa-0"
        >
          <v-card
            flat
            height="180px"
            color="transparent"
            class="d-flex align-center"
          >
            <v-card
              flat
              tile
              width="100%"
              height="100%"
              class="ml-7 mt-3 pl-0"
              style="background: transparent"
            >
              <v-card-title class="text-h4"
                >{{ data.firstname }} {{ data.lastname }}</v-card-title
              >
              <v-card-subtitle class="text-h6">{{
                data.designation
              }}</v-card-subtitle>
              <v-card-text class="d-flex mx-4 pa-0" style="width: 60%">
                <div>
                  <h4 class="mb-1">
                    <v-icon class="mr-2">mdi-phone-in-talk</v-icon>
                    {{ data.tel }}
                  </h4>
                  <h4 class="mb-1">
                    <v-icon class="mr-2">mdi-earth</v-icon> {{ data.website }}
                  </h4>
                </div>
                <v-spacer></v-spacer>
                <div class="mr-4">
                  <h4 class="mb-1">
                    <v-icon class="mr-2">mdi-email</v-icon> {{ data.email }}
                  </h4>
                  <h4 class="mb-1">
                    <v-icon class="mr-2">mdi-map-marker-radius</v-icon> Pune,
                    India
                  </h4>
                </div>
              </v-card-text>
            </v-card>
            <v-avatar color="grey" size="148" class="mr-10">
              <v-img :src="data.photoURL"></v-img>
            </v-avatar>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col cols="8" class="pa-0">
        <v-sheet height="100%" color="white" class="mb-5 pl-6">
          <v-card
            flat
            height="max-content"
            min-height="100px"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-account-edit</v-icon
              >Overview</v-card-title
            >
            <v-card-text class="black--text">
              {{ data.Overview }}
            </v-card-text>
          </v-card>
          <v-divider class="bgColor mx-4"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            color="transparent"
          >
            <v-card-title class="textColor pb-0"
              ><v-icon class="mr-2 textColor" large>mdi-briefcase</v-icon>Work
              Experience</v-card-title
            >
            <v-card-text class="black--text">
              <v-card
                flat
                color="transparent"
                class="pt-0"
                v-for="item in data.workExp"
                :key="item.projectID"
              >
                <v-card-title class="textColor"
                  >{{ item.projectName }}
                  <v-spacer></v-spacer>
                  <span class="caption" style="font-weight: bold">
                    {{ `From: ${item.fromDate} - To: ${item.toDate}` }}</span
                  ></v-card-title
                >
                <v-card-subtitle class="textColor lighten-1"
                  >Company- {{ item.company }}
                  <br />
                  Designation - <em>{{ item.designation }}</em></v-card-subtitle
                >
                <v-card-text class="black--text">
                  {{ item.detail }}
                  <br />
                  <v-chip
                    class="bgColor mr-2 mt-2"
                    label
                    x-small
                    dark
                    v-for="(tag, i) in item.tags"
                    :key="i"
                    >{{ tag }}</v-chip
                  >
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <v-divider class="bgColor mx-4"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-school</v-icon>Education
              & Overview</v-card-title
            >
            <v-card-text class="black--text">
              <v-list two-line color="white">
                <v-list-item-group multiple>
                  <v-list-item v-for="(item, i) in data.education" :key="i">
                    <v-list-item-content>
                      <v-list-item-title class="textColor">{{
                        item.school
                      }}</v-list-item-title>

                      <v-list-item-subtitle class="black--text"
                        ><strong>School -</strong>
                        {{ item.school }}</v-list-item-subtitle
                      >

                      <v-list-item-subtitle class="black--text"
                        ><strong>Course -</strong>
                        {{ item.course }}</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action class="pt-3">
                      <v-list-item-action-text class="black--text"
                        ><strong>Passing Year -</strong>
                        {{ item.passout }}</v-list-item-action-text
                      >
                      <v-list-item-action-text class="black--text"
                        ><strong>Percent/Grades -</strong>
                        {{ item.percent }}</v-list-item-action-text
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col cols="4" class="pa-0">
        <v-sheet height="100%" color="white">
          <v-card
            flat
            height="max-content"
            min-height="100px"
            class="pl-4 pt-3"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-trophy</v-icon
              >Achievements</v-card-title
            >
            <v-card-text class="black--text">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="textColor"
                    >Award 1</v-list-item-title
                  >
                  <v-list-item-subtitle class="black--text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Aperiam hic fuga enim, minima eos quod quia obcaecati cumque
                    aliquid explicabo ea maxime asperiores adipisci libero
                    voluptates et officia voluptatum consequuntur.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="textColor"
                    >Award 2</v-list-item-title
                  >
                  <v-list-item-subtitle class="black--text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Aperiam hic fuga enim, minima eos quod quia obcaecati cumque
                    aliquid explicabo ea maxime asperiores adipisci libero
                    voluptates et officia voluptatum consequuntur.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="textColor"
                    >Award 3</v-list-item-title
                  >
                  <v-list-item-subtitle class="black--text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Aperiam hic fuga enim, minima eos quod quia obcaecati cumque
                    aliquid explicabo ea maxime asperiores adipisci libero
                    voluptates et officia voluptatum consequuntur.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
          <v-divider class="bgColor mx-4"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            class="pl-4"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-wrench</v-icon
              >Skills</v-card-title
            >
            <v-card-text class="black--text">
              <h4
                class="pl-4"
                style="font-weight: normal"
                v-for="(item, i) in data.skills"
                :key="i"
              >
                {{ item }}
                <span class="caption grey--text">( Intermediate)</span>
              </h4>
            </v-card-text>
          </v-card>
          <v-divider class="bgColor mx-4"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            class="pl-4"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-football</v-icon
              >Hobbies</v-card-title
            >
            <v-card-text class="black--text">
              <h4
                class="pl-4"
                style="font-weight: normal"
                v-for="(item, i) in data.hobbies"
                :key="i"
              >
                {{ item }}
              </h4>
            </v-card-text>
          </v-card>
          <v-divider class="bgColor mx-4"></v-divider>
          <v-card
            flat
            height="max-content"
            min-height="100px"
            class="pl-4"
            color="transparent"
          >
            <v-card-title class="textColor"
              ><v-icon class="mr-2 textColor" large>mdi-google-translate</v-icon
              >Languages</v-card-title
            >
            <v-card-text class="black--text">
              <h4
                class="pl-4"
                style="font-weight: normal"
                v-for="(item, i) in data.langs"
                :key="i"
              >
                {{ item }} <span class="caption grey--text">(Proficient)</span>
              </h4>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["data"],
  created() {
    console.log(this.data);
  },
};
</script>
<style scoped>
.bgColor {
  background-color: green !important;
}

.textColor {
  color: green !important;
}
</style>
