<template>
  <v-container class="pa-0">
    <div class="d-flex">
      <v-text-field
        dense
        outlined
        type="text"
        class="mr-2"
        v-model="data.school"
        label="School/College"
      ></v-text-field>
      <v-text-field
        dense
        outlined
        type="text"
        v-model="data.course"
        label="Specialization"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field
        dense
        outlined
        type="text"
        class="mr-2"
        v-model="data.passout"
        label="Passing Year"
      ></v-text-field>
      <v-text-field
        dense
        outlined
        type="text"
        v-model="data.percent"
        label="Percent(%) or CGPA"
      ></v-text-field>
    </div>
  </v-container>
</template>
<script>
export default {
    props:['data']
}
</script>